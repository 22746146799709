import firebase from "firebase/app"
import 'firebase/firestore'
import 'firebase/database'

var firebaseConfig = {
  apiKey: "AIzaSyDu9oPaEAkPZ2YTGs14hcvTG23YK4XomKQ",
  authDomain: "letsema-ngo.firebaseapp.com",
  databaseURL: "https://letsema-ngo-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "letsema-ngo",
  storageBucket: "letsema-ngo.appspot.com",
  messagingSenderId: "995682625866",
  appId: "1:995682625866:web:e351139d56860d2483f9b3"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

//firebase.analytics()

export default firebase