import React, { useState } from "react"
import PropTypes from "prop-types"

import Survey from '../LETSEMA_NGO_THE_STATE_OF_DIGITALISATION_2021_SA.pdf'

import styled from 'styled-components'
import disableScroll from 'disable-scroll'

import Logo from '../images/logo.png'
import { DownloadButton, FormModal } from "../styles"
import media from "../styles/media"

import { Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import firebase from "../utils/firebase"

import { RiDownloadLine, RiErrorWarningFill }from 'react-icons/ri'
import { isMobile } from "react-device-detect"

const HeaderDiv = styled.header`
  width: 100vw;
  padding: 0 10px;
  display:flex;
  justify-content: space-between;
  position:absolute;
  align-items:center;
  height: 80px;
  z-index: 9;

  .headLogo {
    border: none;
    background: transparent;
  }

  img {
    width: 140px;
    margin: 0;
  }

  ${media.laptop`
    padding: 0 var(--gutter-sm);
    img {
      width: 200px;
      margin: 0;
    }

    height: 100px;
  `}
`

const Header = ({ navigation, step }) => {
  const [show, setShow] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [keyOpen, setKeyOpen] = useState(false)

  if(typeof window !== 'undefined') {
    var initialScreenSize = window.innerHeight;

    var keyboard = setInterval(() => {
      if (initialScreenSize !== window.innerHeight) {
        setKeyOpen(true)
      } else {
        setKeyOpen(false)
      }
    }, 800);
  }

  const movePage = () => {
    if (typeof window !== 'undefined')  {
      // window.scrollTo(0,0)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
    }
    navigation.go(0)
  }

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  if(show && !isMobile) {
    disableScroll.on();
  } else if(!show && !isMobile) {
    disableScroll.off()
  }

  const { register, handleSubmit, watch, formState: { errors } } = useForm();

  const onSubmit = data => {

    firebase
    .firestore()
    .collection("leads")
    .add({
      fullName: data.fullName,
      email: data.email,
      company: data.company,
      jobTitle: data.jobTitle
    })
    .then(() => {
      setSuccessMsg(true)
      if(typeof window !== 'undefined') {
        document.getElementById('surveyButt').click()
        document.getElementById('leadForm').reset()
      }
      setTimeout(() => {
        setSuccessMsg(false)
      }, 4000);
    })
    .catch((err) => {
      console.log(err);
    })
  }

  return (
    <>
    <HeaderDiv>
      <button className="headLogo" onClick={() => movePage()}>
        <img src={Logo} alt="logo" />
      </button>

      <DownloadButton onClick={handleShow}>
        <span className="mob"><RiDownloadLine /></span>
        <span className="desk">Download Report</span>
      </DownloadButton>
    </HeaderDiv>

      <FormModal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          Download the report
        </Modal.Header>
        <Modal.Body className={keyOpen ? 'open' : 'closed'}>
          <p className="blurb">
            Fill out the form below to get access to this interactive publication.  We promise to take care of your data. All fields marked with an asterisk (*) are required and cannot be left blank.
          </p>

          <form onSubmit={handleSubmit(onSubmit)} id="leadForm">
            <div className="topForm">
              <label>First Name*</label>
              {errors.fullName && <span className="error"><RiErrorWarningFill /> Please enter your full name.</span>}
            </div>
            <input className={errors.fullName ? 'err' : ''} type="text" placeholder="Enter Full Name" {...register("fullName", { required: true })} />
            
            <div className="topForm">
              <label>Email Address*</label>
              {errors.email && <span className="error"><RiErrorWarningFill /> Please enter a valid email address.</span>}
            </div>
            <input className={errors.email ? 'err' : ''} type="email" placeholder="Enter Email Address" {...register("email", { required: true })} />

            <label>Company</label>
            <input type="text" placeholder="Enter Company Name" {...register("company")} />

            <label>Job title</label>
            <input type="text" placeholder="Enter Job Title" {...register("jobTitle")} />
            
            <input type="submit" value="download" />
          </form>

          <a href={Survey} id="surveyButt" download style={{display: 'none'}}>
          <button type="button">Download</button>
          </a>


          {successMsg && (
            <p className="successForm">Thank you for showing interest in our survey.</p>
          )}
        </Modal.Body>
      </FormModal>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
