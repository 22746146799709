import styled from 'styled-components'
import media from './media'
import{ Modal, Dropdown } from 'react-bootstrap'

export const FormModal = styled(Modal)`
  /* margin: 1rem 1.5rem; */
  position: fixed;
    height: 200vh;

    overflow: scroll;

  .open {
    height: 200vh;
  }

  .closed {
    height: fit-content;
  }

  .modal-content{
    font-family: 'Segoe UI';
    background: #303333;
    color: white;
    padding: 20px 10px;

    .modal-header {
      border: none;
      text-transform: uppercase;
      font-size: 22px;
      line-height: 29px;

      button {
        padding: 0;
        background-color: var(--green);
        color: white;
        margin: 0;
        opacity: 1;
        width: 20px;
        height: 20px;
        padding: 3px;
        transition: all .3s linear;
        border-radius: 0 !important;

        &:hover {
          background-color: #0b0b0b;
        }
      }

      .btn-close {
        background: var(--green) url('https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../assets/preview/2012/png/iconmonstr-x-mark-2.png&r=255&g=255&b=255') center/.55em auto no-repeat;
      }
    }

    .modal-body {
      font-size: 14px;
      line-height: 19px;

      form {
        display: flex;
        flex-direction: column;

        .topForm {
          display: flex;
          justify-content: space-between;
        }

        label {
          margin: 15px 0 5px;
        }

        span {
          margin: 15px 0 5px;
          color: #FFC701;
        }

        input {
          height: 45px;
          border: none;
          padding: 0 10px;

          &:focus-visible {
            outline: none;
          }

          &.err {
            border: 2px solid #FFC701;
          }
        }
        input[type=submit] {
          margin: 25px 0 0;
          border: none;
          background: var(--green);
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: #FFFFFF;
          transition: all .3s linear;

          &:hover {
            background: #0b0b0b;
          }
        }
      }
    }
  }
`

// HEADER
export const LargeHeading = styled.p`
  font-size: 11vw;
  line-height: 50px;
  text-transform: uppercase;
  font-family: 'Segoe Light';

  ${media.laptop`
    font-size: 96px;
    line-height: 100px;
  `}
`

export const Heading = styled.p`
  font-size: 40px;
  line-height: 48px;
  text-transform: uppercase;
  font-family: 'Segoe Light';

  .line{
    height: 40px;
    position: relative;
    overflow: hidden;
    display: flex;
    margin-top: 10px;
    span{
      position: absolute;
      margin-top: 100%;
      margin-top: 40px;
      /* font-size: 3rem;
      line-height: 4.2rem; */
    }
  }

  &.center {
    .line{
      height: 40px;
      position: relative;
      overflow: hidden;
      display: flex;
      justify-content: center;
    }
  }

  b {
  font-family: 'Segoe Semi';
  }

  ${media.laptop`
    font-size: 4.25vw;
    letter-spacing: -2px;
    line-height: 1.25;


    .line{
    height: 4.5vw;
    position: relative;
    overflow: hidden;
    span{
      position: absolute;
      margin-top: 4.5vw;
    }
  }

    &.center {
      text-align: center;

      .line{
      height: 4.5vw;
      position: relative;
      overflow: hidden;
      justify-content: center;
      }
    }


  `}
`

export const Body = styled.p`
  font-size: 14px;
  line-height: 19px;

  &.upper {
    text-transform: uppercase;
  }

  &.top {
    margin: 0;
  }

  &.lined {
    border-bottom: 1px solid #414141;
    padding: 0 0 25px;
  }

  &.imgText {
    font-size: 18px;
    line-height: 1.5;
  }

  &.home {
    font-size: 18px;
    line-height: 24px;
    margin-right: 15%;
  }

  ${media.laptop`
    font-size: 18px;
    line-height: 24px;

    &.max {
      max-width: 520px;
    }

    &.home {
      font-size: 22px;
      line-height: 29px;
    }

    &.topSpace {
      margin-top: 45px;
    }

    &.topSpaceLg {
      margin-top: 62px;
    }

    &.large {
      font-size: 30px;
      line-height: 40px;
    }

    &.lined {
      border-bottom: 2px solid #414141;
    }
  `}
`

export const IntroHead = styled.p`
  color: var(--light-green);
  text-transform: uppercase;
  font-family: 'Segoe Semi';

  ${media.laptop`
    font-size: 14px;
    line-height: 19px;
`}
`

export const SubHeading = styled.p`
  font-size: 24px;
  line-height: 32px;

  &.keyTake {
    font-size: 18px;
    line-height: 22px;
  }

  ${media.laptop`
    font-size: 36px;
    line-height: 48px;


    &.keyTake {
      font-size: 24px;
      line-height: 36px;
    }
  `}
`

export const GreenText = styled.p`
  color: var(--light-green);
  text-transform: uppercase;
  font-family: 'Segoe Semi';

  ${media.laptop`
    font-size: 22px;
    line-height: 29px;
  `}
`

export const NumberText = styled.p`
  
  ${media.laptop`
    font-size: 72px;
    line-height: 96px;
  `}
`

export const DownloadButton = styled.button`
  width: 30px;
  height: 30px;
  background: #303333;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-family: 'Segoe UI';
  border: none;
  transition: all .3s linear;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--green);
  }

  ${media.tablet`
    width: 210px;
    height: 50px;
  `}
`

export const NavBottom = styled.div`
  position: fixed;
  bottom: var(--gutter-sm);
  right: 5vw;
  display: flex;
  z-index: 999;

  .index {
    background: var(--green);
    color: white;
    text-transform: uppercase;
    margin: 0 2px;
    padding: 0 15px;
    font-size: 18px;
    line-height: 10px;
    font-family: 'Segoe UI';
    background: transparent;
    border: none;
  }

  .navbutt {
    border: none;
    color: white;
    height: 30px;
    text-transform: uppercase;
    background: var(--green);
    display: flex;
    align-items: center;
    font-family: 'Segoe UI';
    transition: all .3s linear;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .prev {
    /* padding-right: 15px; */
  }
  .next {
    /* padding-left: 15px; */
  }

  ${media.laptop`
    .navbutt {
      &:hover {
        background: #303333;
      }
    }
  `}
`

export const QuoteText = styled.p`
  font-size: 18px;
  line-height: 24px;

  &.author {
    font-style: italic;
  }

  ${media.laptop`
    font-size: 26px;
    line-height: 35px;
  `}
`

export const FormHeading = styled.p`
  background: #0b0b0b;
  border-radius: 50px;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  font-family: 'Segoe Semi';

  button {
    position: absolute;
    right: 0px;
    margin-top: 0px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: none;
    background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    color: white;
    transition: all .3s linear;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    /* svg {
      width: 20px;
    } */
  }

  ${media.laptop`
    font-size: 24px;
    line-height: 24px;
    padding: 15px 0;
    /* width: 800px; */

    button {
      margin-top: 0px;
      /* right: calc((calc(0.5 * calc(100vw - 800px)) - 2px )); */
      right: -10px;
      margin-left: 0px;
      width: 40px;
      height: 40px;

      &:hover {
        background: #303333;
      }

      svg {
        /* margin-top: -4px; */
      }
    }
  `}
`

export const FormDescript = styled.p`
  text-align: center;
  color: #B6B6B6;
  font-size: 14px;
  line-height: 19px;

  ${media.laptop`
    font-size: 18px;
    line-height: 24px;
    width: 800px;
  `}
`

export const MenuModal = styled(Modal)`
  .modal-dialog {
    position: absolute;
    bottom: 50px;
    right: 10px;

    .modal-content {
      z-index:9;
      padding: 10px 0px 15px;
      background: #303333;
      color: white;
      width: calc(100vw - 40px);
      font-family: 'Segoe UI';

      .modal-header {
        border: none;

        .btn-close {
          background: transparent url('https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../assets/preview/2012/png/iconmonstr-x-mark-2.png&r=255&g=255&b=255') center/.55em auto no-repeat;
          &:focus {
            outline: none;
            box-shadow:none;
          }
          &:focus-visible {
            outline: none;
          }
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;
        padding: 0 1rem 1rem;

        .dropHead {
          margin-bottom: 20px;
          font-size: 16px;
          line-height: 21px;
          text-transform: uppercase;
        }

        button {
          color: white;
          text-transform: none;
          margin: 0 2px;
          font-size: 10px;
          line-height: 10px;
          background: transparent;
          border: none;
          text-align: left;
          font-size: 14px;
          line-height: 19px;
          padding: 10px 0;
          border-bottom: 2px solid #414141;
          transition: all .3s linear;

          &:hover {
            color: var(--green);
          }
        }
      }
    }
  }

  ${media.tablet`
    .modal-dialog {
      bottom: 60px;
      right: 60px;

      .modal-content {
        padding: 15px 0px 20px;
        width: 455px;
      }
    }
  `}
`


export const InfoModal = styled(Modal)`
  .modal-dialog {
    max-width: 100%;
    .modal-content {
      z-index:9;
      padding: 10px 0px 15px;
      background: #000000;
      color: white;
      width: calc(100vw - 40px);
      font-family: 'Segoe UI';

      .modal-header {
        border: none;

        .btn-close {
          background: transparent url('https://iconmonstr.com/wp-content/g/gd/makefg.php?i=../assets/preview/2012/png/iconmonstr-x-mark-2.png&r=255&g=255&b=255') center/.55em auto no-repeat;
          &:focus {
            outline: none;
            box-shadow:none;
          }
          &:focus-visible {
            outline: none;
          }
        }
      }

      .modal-body {
        .infoModalTop {
          margin: -50px 5vw 30px;
          font-size: 18px;
          line-height: 26px;
          text-align: center;
        }

        .infoModalList {
          font-size: 12px;
          line-height: 18px;
        }

        span {
          color: var(--light-green);
        }
      }
    }
  }

  ${media.tablet`
    .modal-dialog {
      /* left:12.5vw; */
      max-width:900px;
      .modal-content {
        /* width: 70vw; */
      max-width:900px;

        .modal-body {

          .infoModalTop {
            margin: -50px 5vw 30px;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
          }

          .infoModalList {
            font-size: 16px;
            line-height: 21px;

            li {
              margin: 0 0 20px;
            }
          }
        } 

      }
    }
  `}
`

export const LargeUnder = styled.div`
  margin: 0 0 50px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;

  ${media.laptop`
    flex-direction: row;
    justify-content: center;
    margin: 0 0 100px;
  text-align: left;
  `}
`

export const LargeNo = styled.p`
  font-size: 64px;
  line-height: 96px;
  color: var(--green);

  ${media.laptop`
    font-size: 96px;
    line-height: 96px;
    margin-right: 45px;
  `}
`

export const LargeInfo = styled.p`
    font-size: 18px;
    line-height: 24px;
  color: white;

    ${media.laptop`
      font-size: 36px;
      line-height: 48px;
    `}
`

export const InnerText = styled.p`
  position: absolute;
  top: 22vh;
  left: 70px;
  max-width: calc(100vw - 100px);
  transition: all .5s linear;

  /* &.hidden{
    opacity: 0;
  } */

  &.visible {
    opacity: 1;
  }

  ${media.tablet`
    top: 30vh;
    left: 120px;
    max-width: calc(100vw - 300px);
  `}
`

export const LegendDiv = styled.div`
display: flex;
  position: absolute;
  bottom: -0px;

  &.spaceLeft {
    width: 45px;
    place-content: center;
    position: relative;

    .colourBlock {
      width: 20px;
      height: 20px;
      font-family: 'Segoe Semi';
      font-size: 8px;
    }
  }

  &.four {
    display: grid;
    grid-template-columns: repeat(4, 1fr);

    .zero {
      .colourBlock {
        width: 30px;
        height: 16px;
      }
    }
  }

  &.lower{
    bottom: -70px;
  }

  &.lowest {
    bottom: -60px;
  }

  .zero, .first, .second, .third, .fourth, .fifth {
    display: flex;
    margin: 0 8px;
    align-items: center;
    text-transform: uppercase;
    font-size: 6px;
    line-height: 8px;

    p {
      margin: 0;
    }
  }

  .colourBlock {
    width: 15px;
    height: 15px;
    margin: 5px;
    display: flex;

    &.zero {
      background: #AED363;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
    }
    
    &.one {
      background: linear-gradient(180deg, #61AC4A 0%, #1F580D 100%);
    }
    &.two {
      background: linear-gradient(180deg, #1A6968 0%, #064544 100%);
    }
    &.three {
      background: linear-gradient(180deg, #636363 0%, #434343 100%);
    }
    &.four {
      background: #0A2E46;
    }
    &.five {
      background: #61AC4A;
    }
  }

  ${media.laptop`
    .zero, .first, .second, .third, .fourth, .fifth {
      display: flex;
      margin: 0 50px;
      font-size: 14px;
      line-height: 19px;
    }

    .zero {
      margin: 0 0 0 15px;
    }

    &.four {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .zero {
        margin: 0 5px;
        .colourBlock {
          width: 28px;
          height: 28px;
        }
      }
    }
  
    .colourBlock {
      width: 28px;
      height: 28px;
    }

    &.spaceLeft {
      .colourBlock {
        width: 43px;
        height: 43px;
        margin-right: 0;
        font-size: 14px;
      }
    }

    &.lowest {
      bottom: -100px;
    }
  `}
`

export const SideText = styled.p`
  text-transform: uppercase;
  transform:rotate(270deg);
  margin-left: 0;
  position: absolute;
  /* top: 550px; */
  margin-top: 250px !important;
  left: -43vw;
  text-transform: uppercase;
  color: #a4a4a4;
  font-size: 10px;
  line-height: 13px;

  ${media.laptop`
    display: block;;
    text-transform: uppercase;
    transform:rotate(270deg);
    position: absolute;
    /* top: 380px; */
    margin-top: -12% !important;
    left: -45vw;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.1em;

    &.top {
      margin-top: 0 !important;
    }
  `}
`