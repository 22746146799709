/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import { MenuModal, NavBottom } from '../styles'

import Header from "./header"
import "./layout.css"
import { Modal } from "react-bootstrap"

import { RiMenu5Fill } from 'react-icons/ri'
import styled from "styled-components"
import media from "../styles/media"
import { Link } from "gatsby"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs"
import { Helmet } from "react-helmet"

import Social from '../images/NGO_Social_Share.png'

const CookieContainer = styled.div`
  display: flex;
  font-family: 'Segoe UI';

  .cookiesConsent {
    width: 96vw; 
    height: 80px; 
    position: fixed;
    left: 2vw; 
    bottom: 20px;
    background: #000000;
    z-index: 999;
    padding: 0 20px;

    button {
      border: none;
      background: var(--green);
      transition: all .3s linear;
      color: white;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 16px;

      width: 100%;
      height: 30px;
    }

    p {
      margin: 0;
      color: #EAEAEA;
      font-size: 12px;
      line-height: 16px;
      padding: 10px 0;

      a {
      color: #EAEAEA;
        transition: all .3s linear;
        &:hover {
          color: var(--light-green);
        }
      }
    }

    &.hidden {
      opacity: 0;
      z-index: -1;
    }
  }

  ${media.laptop`
    .cookiesConsent {
      width: 550px; 
      height: 60px; 
      bottom: 40px;
      left: 40px;
      box-shadow: 5px 6px 10px rgb(0 0 0 / 25%);
      padding: 0 30px;

      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        width: 170px;
        height: 30px;
        font-size: 12px;
        line-height: 16px;

        &:hover {
          background: #303333;
        }
      }

      p {
        font-size: 14px;
        line-height: 19px;
        padding: 0;

        a {
          transition: all .3s linear;
          &:hover {
            color: var(--light-green);
          }
        }
      }
    }
  `}
`

const Layout = ({ children, navigation, step }) => {
  const props = { navigation, step };
  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const movePage = (number) => {
    console.log(number);
    if (typeof window !== 'undefined')  {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
    }
    navigation.go(number)
    setShow(false)
  }

  const moveRight = () => {
    if (typeof window !== 'undefined')  {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
    }
    navigation.next()
  }

  const moveLeft = () => {
    if (typeof window !== 'undefined')  {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
    }
    navigation.previous()
  }

  // cookies
  const [cookiesAccepted, setCookiesAccepted] = useState(false)

  if (typeof window !== 'undefined') {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  return (
    <>
      {/* <Header {...props}  /> */}

      <Helmet>
        <title>The State of Digitalisation 2021</title>
        <meta name="description" content="Welcome to the first annual Letsema Next-Gen Operations State of Digitalisation Survey, where we gather and present insights on how digitalisation is redefining our future of work." data-react-helmet="true" />
        <meta property="og:image" content={Social}/>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
      </Helmet>

      <main>{children}</main>

      <NavBottom>
        <button className="navbutt prev" disabled={step.id === 'home'} onClick={() => moveLeft()}><BsChevronLeft /> </button>
        <button className="navbutt index" onClick={() => setShow(!show)}><RiMenu5Fill /> </button>
        <button className="navbutt next" disabled={step.id === 'step13'} onClick={() => moveRight()}><BsChevronRight /> </button>
      </NavBottom>

        <MenuModal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            INDEX
          </Modal.Header>
          <Modal.Body>
            <button onClick={() => movePage(1)}>1 &nbsp;&nbsp;Introduction</button>
            <button onClick={() => movePage(2)}>2 &nbsp;&nbsp;Methodology</button>
            <button onClick={() => movePage(3)}>3 &nbsp;&nbsp;Key Market Study Insights</button>
            <button onClick={() => movePage(5)}>4 &nbsp;&nbsp;Demographic Breakdown of Participants</button>
            <button onClick={() => movePage(6)}>5 &nbsp;&nbsp;Survey Results: Digitalisation Strategy</button>
            <button onClick={() => movePage(7)}>6 &nbsp;&nbsp;Survey Results: Implementation of Digital Strategies</button>
            <button onClick={() => movePage(9)}>7 &nbsp;&nbsp;Digital Archetypes</button>
            <button onClick={() => movePage(11)}>8 &nbsp;&nbsp;Close out and Implications for the Future</button>
            <button onClick={() => movePage(13)}>9 &nbsp;&nbsp;About NGO</button>
          </Modal.Body>
        </MenuModal>
        
        <CookieContainer>
          <div className={cookiesAccepted ? 'cookiesConsent hidden' : 'cookiesConsent visible'}>
              <p className="text">This website uses cookies. {" "} <a href="https://letsema.co.za/terms-and-conditions/" target="_blank">Learn more.</a></p>
              <button onClick={() => setCookiesAccepted(true)}>Accept Cookies</button>
          </div>
        </CookieContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
